import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Message from '@/components/global/Message'
import Loading from '@/components/global/Loading'
import dateFilter from '@/components/filters/date.filter'
import htmlFilter from '@/components/filters/html.filter'
import clickOutside from '@/components/directives/outside.js'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

Vue.config.productionTip = false
Vue.component('message', Message)
Vue.component('loading', Loading)
Vue.filter('date', dateFilter)
Vue.filter('html', htmlFilter)
Vue.directive('click-outside', clickOutside)
Vue.use( CKEditor )
Vue.use(CoolLightBox)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
