import axios from 'axios'

export default {

  state: {
    address: {
      address: '',
      map: ''
    },
    email: [],
    fax: [],
    tel: []
  },

  mutations: {
    mutAddress(state, payload) {
      state.address = {}
      state.address = payload
    }
  },

  actions: {

    async address({commit, rootState}) {
      let response = await axios({
        method: 'POST',
        url: `${rootState.host}/address`
      })
      let result = await response.data
      commit('mutAddress', result)
    },

    async contactInsert({dispatch, rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/contactInsert`,
        method: 'POST',
        data: payload
      })
      let result = await response.data
      dispatch('address')
      return result
    },

    async contactDelete({dispatch, rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/contactDelete`,
        method: 'POST',
        data: payload
      })
      let result = await response.data
      dispatch('address')
      return result
    }

  },

  getters: {
    getAddress: state => state.address
  }

}
