import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {layout: 'HomeLayout', auth: false},
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/admin_panel',
    name: 'login',
    meta: {layout: 'LoginLayout', auth: false},
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/production',
    name: 'production',
    meta: {layout: 'AdminLayout', auth: true},
    component: () => import('@/views/Production.vue')
  },
  {
    path: '/main',
    name: 'main',
    meta: {layout: 'AdminLayout', auth: true},
    component: () => import('@/views/Main.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    meta: {layout: 'AdminLayout', auth: true},
    component: () => import('@/views/Contacts.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {layout: 'AdminLayout', auth: true},
    component: () => import('@/views/Settings.vue')
  },
  {
    path: '/prodpage',
    name: 'prodpage',
    meta: {layout: 'HomeLayout', auth: false},
    component: () => import('@/views/ProdPage.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.auth)) {
    store.dispatch('host').then(() => {
      store.dispatch('checkLogin').then(() => {
        if(store.getters.authStatus == 'success') {
          next()
        }
        if(store.getters.authStatus == 'error') {
          next({
            path: '/admin_panel'
          })
        }
      })
    })
  } else {
    next()
  }
})

export default router
