<template>
  <div>
    <TopBanner/>
    <v-main>
      <router-view 
        :loadingWindow="loadingWindow"
      />
    </v-main>
    <Footer/>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {TopBanner, Footer},
  props: {
    loadingWindow: Function
  },
  data: () => ({
    
  }),
  methods: {
    
  },
  mounted() {
    if(this.$route.path != '/') document.querySelector('#topBanner').classList.add('views')
  },
  created () {
    this.$vuetify.theme.dark = false
  },
}
</script>

<style lang="scss" scoped>
  
</style>
