import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import auth from './auth'
import production from './production'
import contacts from './contacts'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    host: '',
    logo: ''
  },
  mutations: {
    mutHost(state, payload) {
      state.host = ''
      state.logo = ''
      state.host = payload.host
      state.logo = payload.logo
    }
  },
  actions: {

    async host({commit}) {
      let response = await axios({
        method: 'GET',
        url: './json/host.json'
      })
      let result = await response
      commit('mutHost', result.data)
    },

    async logoUpdate({rootState}, payload) {
      let data = new FormData()
      data.append('file', payload.file)
      let response = await axios({
        url: `${rootState.host}/logoUpdate`,
        method: 'POST',
        data: data
      })
      let result = await response.data
      return result
    }

  },

  getters: {
    getLogo: state => state.logo
  },

  modules: {
    auth, production, contacts
  }
})
