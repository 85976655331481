<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">
          Контакты
        </v-card-title>
        <v-card-text>
          <div class="map">
            <v-progress-circular
              v-if="!visible"
              :size="50"
              :width="3"
              color="rgb(99, 99, 99)"
              indeterminate
              class="load"
            ></v-progress-circular>
            <iframe 
              v-show="visible"
              :src="getAddress.address.map" 
              width="100%" 
              height="200" 
              frameborder="0"
              @load="visible = true"
            ></iframe>
          </div>
          <div class="address">
            <div>
              <span><v-icon small left>mdi-map-marker-radius</v-icon> Адрес:</span>
              <span>{{getAddress.address.address}}</span>
            </div>
            <div>
              <span><v-icon small left>mdi-phone-classic</v-icon> Телефоны:</span>
              <span v-for="(t, i) in getAddress.tel" :key="`${i}+tel`">
                <a :href="`tel:${t.tel}`">{{t.tel}}</a>
              </span>
            </div>
            <div>
              <span><v-icon small left>mdi-fax</v-icon> Факс:</span>
              <span v-for="(f, i) in getAddress.fax" :key="`${i}+fax`">
                <a :href="`tel:${f.fax}`">{{f.fax}}</a>
              </span>
            </div>
            <div>
              <span><v-icon small left>mdi-email</v-icon> E-mail:</span>
              <span v-for="(e, i) in getAddress.email" :key="`${i}+email`">
                <a :href="`mailto:${e.email}`">{{e.email}}</a>
              </span>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="rgb(99, 99, 99)"
            text
            @click="dialog = false"
          >
            закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {mapGetters} from 'vuex'
  export default {
    data: () => ({
      visible: false,
      dialog: false,
    }),
    computed: {
      ...mapGetters(['getAddress'])
    },
    methods: {
      show() {
        this.dialog = true
      }
    },
    mounted() {
      this.$emit('contacts', this.show)
    }
  }
</script>

<style lang="scss" scoped>
  .map {
    display: grid;
    .load {
      margin: 0 auto;
    }
  }
  .address {
    div {
      margin: 7px 0;
      span {
      display: block;
      a {
          color: #757575;
          text-decoration: none;
        }
      }
    }
  }
  .v-card__actions {
    display: grid;
    justify-items: center;
  }
</style>
