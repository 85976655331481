<template>
  <div class="footer">
    <v-footer
      absolute
      padless
      dark
    >
      <v-card
        flat
        tile
        width="100%"
        class="footer-card lighten-1 text-center"
      >
        <v-card-text class="card-txt">
          <div class="footer-address app-container">
            <div>
              <span><v-icon small left>mdi-map-marker-radius</v-icon> Адрес:</span>
              <span>{{getAddress.address.address}}</span>
            </div>
            <div>
              <span><v-icon small left>mdi-phone-classic</v-icon> Телефоны:</span>
              <span v-for="(t, i) in getAddress.tel" :key="`${i}+tel`">
                <a :href="`tel:${t.tel}`">{{t.tel}}</a>
              </span>
            </div>
            <div>
              <span><v-icon small left>mdi-fax</v-icon> Факс:</span>
              <span v-for="(f, i) in getAddress.fax" :key="`${i}+fax`">
                <a :href="`tel:${f.fax}`">{{f.fax}}</a>
              </span>
            </div>
            <div>
              <span><v-icon small left>mdi-email</v-icon> E-mail:</span>
              <span v-for="(e, i) in getAddress.email" :key="`${i}+email`">
                <a :href="`mailto:${e.email}`">{{e.email}}</a>
              </span>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          &copy; {{ new Date().getFullYear() }} | <span>ООО «ТрейдВит»</span>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data: () => ({
    
  }),
  computed: {
    ...mapGetters(['getAddress'])
  },
}
</script>

<style lang="scss" scoped>
  .footer {
    margin-top: 250px;
    background-color: #ffffff;
    .footer-card {
      background-color: #232323;
      .card-txt {
        .footer-address {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          justify-items: center;
          gap: 15px;
          div {
            text-align: left;
            span {
              display: block;
              a {
                color: rgba(255, 255, 255, 0.7);
                text-decoration: none;
              }
              &:nth-child(1) {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1205px) {
    .footer-address {
      justify-items: left !important;
    }
  }
  @media only screen and (max-width: 596px) {
    .footer {
       margin-top: 350px;
    }
  }
</style>