import axios from 'axios'
export default {
  state: {
    status: '',
    token: null,
    user : '',
    id: null
  },

  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, result){
      state.status = 'success'
      state.token = result.data.token
      state.user = result.data.user
      state.id = result.data.id
    },
    auth_check(state, data){
      state.status = 'success'
      state.token = data.token
      state.user = data.user
      state.id = data.id
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
      state.user = ''
    }
  },

  actions: {
    async checkLogin({commit, rootState}) {
      try {
        if(localStorage.getItem('token')) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
          let response = await axios({
            method: 'POST',
            url: `${rootState.host}/auth`,
            data: {id: rootState.auth.id}
          })
          let result = await response
          let data = {id: result.data.id, user: result.data.user, token: localStorage.getItem('token')}
          commit('auth_check', data)
        } else {
          commit('auth_error')
        }
      } catch (e) {
        commit('auth_error')
      }
    },

    async login ({commit, rootState}, {user, pass}) {
      try {
        commit('auth_request')
        let response = axios({
          url: `${rootState.host}/login`,
          method: 'POST',
          headers: {'Content-type': 'application/json;charset=utf-8'},
          data: {user, pass}
        })
        let result = await response
        if (result.data.auth == true) {
          localStorage.setItem('token', result.data.token)
          axios.defaults.headers.common['Authorization'] = `Bearer ${result.data.token}`
          commit('auth_success', result)
        }
      } catch (error) {
        commit('auth_error')
      }
    },

    logout({commit}){
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token')
        resolve()
      })
    },

    async updatePass({rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/updatePass`,
        method: 'POST',
        data: payload
      })
      let result = await response.data
      return result
    }

  },

  getters: {
		isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    getUser: state => state.user,
    getUserID: state => state.id
	}
}