<template>
  <div class="wrapper" id="topBanner">
    <div class="text app-container">
      <span><v-img :src="getLogo" max-width=350></v-img></span>
      <span><p>Опыт и технологии - путь к успеху</p></span>
    </div>
    <v-img
      class="topBanner"
      :src="img"
      gradient="rgba(0,0,0,.35), rgba(0,0,0,.55)"
    ></v-img>
    <div class="scroll-downs">
      <div class="mousey">
        <div class="scroller"></div>
      </div>
    </div>
    <nav class="bannerMenu" id="bannerMenu">
      <ul>
        <li v-for="(l, i) in link" :key="`link${i}`" @click="clickLink(l.id)">
          <span class="link">{{l.link}}</span>
        </li>
      </ul>
    </nav>
    <Contacts @contacts="contacts = $event"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Contacts from '@/components/Contacts.vue'
export default {
  components: {Contacts},
  data: () => ({
    contacts: Function,
    submenu: false,
    img: 'img/banner.jpg',
    link: [
      {link: 'главная', id: 'main'},
      {link: 'продукция', id: 'production'},
      {link: 'контакты', id: 'contact'},
    ],
  }),
  computed: {
    ...mapGetters(['getLogo'])
  },
  methods: {
    clickLink(id) {
      if(id == 'main' && this.$route.path != '/') {
        this.$router.push('/')
        document.querySelector('#topBanner').classList.remove('views')
      }
      if(id == 'main' && this.$route.path == '/') window.scrollTo({top: 0, behavior: "smooth"})
      if(id == 'production' && this.$route.path != '/') {
        document.querySelector('#topBanner').classList.remove('views')
        this.$router.push('/')
        setTimeout(() => {
          document.querySelector('#production').scrollIntoView({
            block: 'start',
            behavior: 'smooth'
          })
        }, 100);
      } else {
        if(id == 'production' && this.$route.path == '/') {
          document.querySelector('#production').scrollIntoView({
            block: 'start',
            behavior: 'smooth'
          })
        }
      }
      if(id == 'contact') this.contacts()
    }
  },
  mounted() {
    document.onscroll = () => {
      const topBanner = document.querySelector('#topBanner').getBoundingClientRect().bottom
      if(topBanner <= 30) {
        document.querySelector('#bannerMenu').classList.add('fixed')
      } else {
        document.querySelector('#bannerMenu').classList.remove('fixed')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .views {
    .text {
      span {
        &:nth-child(1) {
          margin: 12px auto 25px;
        }
        &:nth-child(2) {
          display: none;
        }
      }
    }
    .topBanner {
      height: 19vh;
    }
    .scroll-downs {
      display: none;
    }
  }
  .wrapper {
    position: relative;
    margin-bottom: 110px;
  }
  .topBanner {
    width: 100%;
    height: 90vh;
  }
  .text {
    color: #ffffff;
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 39%;
    left: 50%;
    transform: translate(-50%, -39%);
    width: 100%;
    font-family: 'Teko', sans-serif;
    font-weight: 600;
        text-shadow: 3px 3px 9px #000000;
    span {
      &:nth-child(1) {
        /* font-size: 3.5em; */
        display: block;
        max-width: 370px;
        margin: 0 auto 25px;
      }
      &:nth-child(2) {
        font-size: 1.9em;
        text-transform: uppercase;
      }
    }
  }
  .bannerMenu {
    width: 700px;
    height: 60px;
    padding: 20px 0px 20px 0px;
    background-color: #fff;
    z-index: 2;
    position: absolute;
    bottom: -41px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 50px 0px #2E2E2E;
    border-radius: 3px;
    transition: all .2s;
    ul {
      text-align: center;
      display: table;
      margin: 0 auto;
      position: relative;
      padding-left: 0;
      li {
        display: inline-block;
        padding: 0 10px;
        user-select: none;
        cursor: pointer;
        border-right: solid 2px #505050;
        text-transform: uppercase;
        font-weight: bold;
        color: #505050;
        &:last-of-type  {
          border-right: none;
        }
        span {
          position: relative;
        }
      }
    }
  }
  .scroll-downs {
    position: absolute;
    display: block;
    top: auto;
    right: 0;
    bottom: 135px;
    left: 0;
    margin: auto;
    width: 34px;
    height: 55px;
    .mousey {
      width: 3px;
      padding: 10px 15px;
      height: 35px;
      border: 2px solid #fff;
      border-radius: 25px;
      opacity: 0.75;
      box-sizing: content-box;
    }
    .scroller {
      width: 3px;
      height: 10px;
      border-radius: 25%;
      background-color: #fff;
      animation-name: scroll;
      animation-duration: 2.2s;
      animation-timing-function: cubic-bezier(.15,.41,.69,.94);
      animation-iteration-count: infinite;
    }
    @keyframes scroll {
      0% {
        opacity: 0;
      }
      10% {
        transform: translateY(0);
        opacity: 1;
      }
      100% {
        transform: translateY(15px);
        opacity: 0;
      } 
    }
  }
  .link:after {
    display: block;
    content: "";
    background-color: rgb(99, 99, 99);
    height: 3px;
    width: 0%;
    left: 50%;
    position: absolute;
    -webkit-transition: width .2s ease-in-out;
    -moz--transition: width .2s ease-in-out;
    transition: width .2s ease-in-out;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .link:hover:after,
  .link:focus:after {
      width: 100%;
  }
  .fixed {
    position: fixed;
    top: 0;
    width: 100%;
  }
  /* @media only screen and () */
</style>