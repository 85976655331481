import axios from 'axios'

export default {

  state: {
    prodItem: [],
    prod: [],
    images: []
  },

  mutations: {
    mutProdItem(state, payload) {
      state.prodItem = []
      state.prodItem = payload
    },
    mutProd(state, payload) {
      state.prod = []
      state.prod = payload
    },
    mutImages(state, payload) {
      state.images = []
      state.images = payload
    },
  },

  actions: {

    async prodItem({commit, rootState}) {
      try {
        let response = await axios({
          method: 'POST',
          url: `${rootState.host}/proditem`
        })
        let result = await response.data
        commit('mutProdItem', result)
      } catch (err) {
        
      }
    },

    async prod({commit, rootState}) {
      try {
        let response = await axios({
          method: 'POST',
          url: `${rootState.host}/prod`
        })
        let result = await response.data
        commit('mutProd', result)
      } catch (err) {
        
      }
    },

    async prodTextUpdate({dispatch, rootState}, payload) {
      try {
        let response = await axios({
          method: 'POST',
          url: `${rootState.host}/prodTextUpdate`,
          data: payload
        })
        let result = await response.data
        dispatch('prod')
        return result
      } catch (err) {
        
      }
    },

    async itemName_Save({dispatch, rootState}, payload) {
      try {
        let response = await axios({
          method: 'POST',
          url: `${rootState.host}/itemNameSave`,
          data: payload
        })
        let result = await response
        await dispatch('prodItem')
        return result.data
      } catch (err) {
        return err
      }
    },

    async itemIMG_Save({dispatch, rootState}, payload) {
      try {
        /* let data = payload */
        let data = new FormData()
        data.append('id', payload.id)
        data.append('imgName', payload.imgName)
        data.append('file', payload.file)
        let response = await axios({
          method: 'POST',
          url: `${rootState.host}/itemIMGSave`,
          data: data
        })
        let result = await response
        await dispatch('prodItem')
        return result.data
      } catch (err) {
        return err
      }
    },

    async uploadFiles({commit, dispatch, rootState}, payload) {
      let formData = new FormData()
      formData.set('item_id', payload.item_id)
      for (const f of payload.files) {
        formData.append('files', f)
      }
      let response = await axios({
        headers: {'Content-Type': 'multipart/form-data'},
        url: `${rootState.host}/uploadProdIMG`,
        method: 'POST',
        data: formData
      })
      let result = await response
      await dispatch('images')
      return result.data
    },

    async deleteFiles({commit, dispatch, rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/deleteProdIMG`,
        method: 'POST',
        data: payload
      })
      let result = await response
      await dispatch('images')
      return result.data
    },

    async images({commit, rootState}) {
      let response = await axios({
        url: `${rootState.host}/images`,
        method: 'POST'
      })
      let result = await response
      commit('mutImages', result.data)
    },

  },

  getters: {
    getProdItem: state => state.prodItem,
    getProd: state => state.prod,
    getImages: state => state.images,
  }

}
